.background-team {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-team {
    display: flex;
    flex-direction: column;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 6rem;
}

.team-heading {
    margin-bottom: 3rem;
}

.team-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

}

.pick-person-slider {
    display: flex;
    gap: 25px;
    margin-top: 2.5rem;
}

.pick-person-text {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 20%;
    height: 100%;
    border-bottom: #212020 20px;
}

img.person-image {
    width: 312px;
    height: 312px;
    transition: filter 0.1s ease-in-out;
}

.person-imagesmall {
    width: 192px;
    height: 192px;
    transition: filter 0.5s ease-in-out;
}

.person-imagesmall.noactive:hover {
    filter: none;
    cursor: pointer;
}
    
.person-imagesmall.active {
    filter: none; 
    cursor: pointer;
}
  
.person-imagesmall.noactive {
    filter: brightness(60%); 
}


  

div.person-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person-moreinfo {
    gap: 1.8rem;
    height: 100%;
    opacity: 0; 
    transform: translateY(10px);
    overflow: hidden; 
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.person-moreinfo.visible {
    display: flex;
    height: 425px !important;
    width: 100%;
    opacity: 1; 
    transform: translateY(0); 
    transition: ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
    pointer-events: auto; 
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px); 
    }
    100% {
        opacity: 1;
        transform: translateY(0); 
    }
}


.person-description {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.person-achievements {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    height: 100%;
}

div.person-achievements-cards-container {
    display: grid;
    grid-template-columns: 244px auto ;
    grid-template-rows: auto auto;
    margin-top: 1rem;
    gap: 1.2rem;
}

div.person-achievements-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    border-radius: 15px;
    text-align: center;
    width: 100%;
}

div.role-description {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    margin-top: 1.5rem;
}

div.person-name {
    background-color: white;
    width: 65%;
    height: 60px;
    margin-top: -3rem;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div.person-education {
    display: flex;
    flex-direction: column;
    width: 28%;
}
div.person-education-container {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    gap: 1rem;

}

div.bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 35px;
    height: 100%;
}

div.person-education-bar {
    position: relative;
    width: 20px;
    height: 100%;
    background-color: white;
    border-radius: 25px;
    z-index: 1;
}

div.person-education-square {
    position: absolute; 
    margin-top: auto; 
    width: 35px;
    height: 35px;
    border-radius: 25px;
    background: #F5C400;
    margin-top: -2rem;
    z-index: 2;
}

div.person-education-text {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}


.swiper-button-prev.swiper-button-disabled {
    display: none;
}

div.swiper-wrapper {
    padding: 0;
    margin: 0;
}

.swiper-slide.swiper-slide-active {
    padding-left: 0px;
}

.mySwiper {
    gap: 1rem;
    width: 100%;
}

.swiper-pagination-progressbar {
    margin-bottom: -5rem;
}

:root {
    --swiper-theme-color: #FFF !important;
}
  
@media screen and (max-width: 800px) {
    .section-team {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 86%;
    }

    .person-moreinfo.visible {
        display: flex;
        margin-top: -3rem;
        flex-direction: column;
        height: 100% !important;
        width: 100%;
        opacity: 1; 
        transform: translateY(0); 
        transition: ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
        pointer-events: auto; 
    }

    div.person-education {
        width: 100% !important;
        display: flex;
    }

    div.person-education-bar, div.person-education-square {
        margin-left: 0.6rem;
    }

    div.person-description {
        width: 100% !important;
    }

    div.person-achievements {
        margin-top: 8rem;
        width: 100% !important;
    }

    div.person-achievements-cards-container {
        display: flex !important;
        flex-direction: column;
        margin-top: 1rem;
        gap: 1.2rem;
    }
}


  /* TABLET POZIOMO */
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-team {
        width: 90%;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-team {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 86%;
    }

    .person-imagesmall {
        width: 192px !important;
        height: 192px !important;
        transition: filter 0.5s ease-in-out;
    }

    .person-moreinfo.visible {
        display: flex;
        margin-top: -3rem;
        flex-direction: column;
        height: 100% !important;
        width: 100%;
        opacity: 1; 
        transform: translateY(0); 
        transition: ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
        pointer-events: auto; 
    }

    div.person-education {
        width: 100% !important;
        display: flex;
    }

    div.person-education-bar, div.person-education-square {
        margin-left: 0.6rem;
    }

    div.person-description {
        width: 100% !important;
    }

    div.person-achievements {
        margin-top: 8rem;
        width: 100% !important;
    }

    div.person-achievements-cards-container {
        display: flex !important;
        flex-direction: column;
        margin-top: 1rem;
        gap: 1.2rem;
    }

    .pick-person-slider {
        display: flex;
        justify-content: flex-start !important;
        gap: 2rem !important;
    }
}