.background-actionsextented {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-actionsextented {
    display: flex;
    flex-direction: column;
    width: 1280px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 6rem;
    margin-bottom: 16rem;
    margin-top: 8rem;
}

div.warsztaty-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
div.warsztaty-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

div.warsztaty-heading {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: 4rem;
}

div.warsztaty-buttons {
    display: flex;
    width: 100%;
    gap: 0.7rem;
    flex-direction: row;
    margin-top: -3rem;
}

button.warsztaty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 15px;
    background: #FFF;
    color: black;
    border: none;
    gap: 0.2rem;
    transition: background 0.3s ease, color 0.3s ease; 
}

button.warsztaty:hover {
    background: #BA9500;
    color: white;
    cursor: pointer;
}

button.warsztaty.active {
    background: #BA9500;
    color: white;
}


p.warsztaty {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
}


div.warsztaty-content {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    gap: 3rem;
}

div.warsztaty-slider {
    display: flex;
    width: 46%;
}


div.warsztaty-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

img.content-img {
    width: 580px;
    height: 93%;
    object-fit: cover;
    border-radius: 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.swiperWarsztaty {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.swiperWarsztaty .swiper-slide {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Upewnij się, że każdy slajd zajmuje pełną wysokość Swipera */
}

.swiper-button-prev, .swiper-button-next, .swiper-button-disabled {
    display: none;
}

div.stats-and-info {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

div.statsW {
    width: 46%;
    display: flex;
    gap: 1rem;
}

div.infoW {
    display: flex;
    align-items: center;
    text-align: end;
}

@media screen and (max-width: 800px) {
    .section-actionsextented {
        width: 80%;
    }

    div.stats-and-info { 
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    div.statsW, div.infoW {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .pick-person-text {
        display: none;
    }

    div.warsztaty-buttons {
        display: flex;
        flex-direction: column;
        width: 112% !important;
    }

    p.warsztaty {
        text-wrap: wrap;
    }

    div.warsztaty-content {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }

    div.warsztaty-slider {
        width: 100%;
    }

    img.content-img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border-radius: 50px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    div.warsztaty-description {
        display: flex;
        gap: 2rem;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-actionsextented {
        width: 80%;
    }

    p.warsztaty {
        text-wrap: wrap;
    }

    div.warsztaty-buttons {
        display: flex;
        flex-direction: column;
        width: 112% !important;
    }

    .pick-person-text {
        display: none;
    }

    p.warsztaty {
        text-wrap: wrap;
    }

    div.warsztaty-content {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }

    div.warsztaty-slider {
        width: 100%;
    }

    img.content-img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border-radius: 50px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    div.warsztaty-description {
        display: flex;
        gap: 2rem;
    }
}

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-actionsextented {
        width: 90%;
    }

    div.warsztaty-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100% !important;
    }

    img.content-img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border-radius: 50px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .pick-person-text h3 {
        text-wrap: nowrap;
    }
}