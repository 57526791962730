.background-header {
    display: flex;
    background-color: #212020;
    height: 150px;
    justify-content: center;
}

.section-header {
    display: flex;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    align-items: center;
}

img.lamaIMG {
    width: 300px;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
}

.logo-header {
    width: 120px;
}

.logo-header:hover {
    animation: shake 0.5s ease-in-out;
}

.column-logo-header {
    width: 10%;
}

.column-navbar-header {
    width: 80%;
    display: flex;
    text-wrap: nowrap;
}

.column-socialmedia-header {
    width: 10%;
    gap: 1rem;
    display: flex;
    justify-content: flex-start;
    margin-left: -1rem;
}

.column-navbar-header ul {
    display: flex;
    list-style-type: none;
    gap: 1.6rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 35px;
}

.column-navbar-header li {
    position: sticky;
}

.column-navbar-header li:hover {
    border-bottom: 5px solid #F5C400;
    cursor: grabbing;
}

.invest {
    color: #F5C400;
    font-weight: 700;
}

.expandIcon {
    width: 20px;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
}

.socialmedia-icon-header:hover {
    animation: shake 0.5s ease-in-out;
    cursor: pointer;
}

/* header.css */

/* Dodaj styl dla rozwijanego menu */
  .expandIcon {
    cursor: pointer;
  }
  
  .expand-menu {
    display: none;
    position: absolute;
    border-radius: 15px;
    width: 250px;
    top: 100%;
    left: 0;
    background-color: #fff; /* Dostosuj kolor tła według potrzeb */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .expand:hover .expand-menu {
    display: block;
  }
  
  /* Dodaj styl dla linków w rozwijanym menu */
  .expand-menu p {
    margin: 0;
    padding: 5px 0;
    cursor: pointer;
    color: #333; /* Dostosuj kolor tekstu według potrzeb */
    font-family: Poppins;
    font-size: 16px;
  }
  
  .expand-menu p:hover {
    background-color: #f0f0f0; /* Dostosuj kolor tła po najechaniu myszą */
  }
  
  .expand.active:hover {
    border: none;
  }

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1550px) {
    .column-socialmedia-header {
        display: none;
    }
    .section-header {
        display: flex;
        flex-direction: column;
    }

    .background-header {
        height: 240px;
    }
    .column-navbar-header {
        width: 100%;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .expand-menu {
        display: none;
        position: absolute;
        border-radius: 15px;
        width: 250px;
        margin-left: -8rem;
        top: 100%;
        left: 0;
        background-color: #fff; /* Dostosuj kolor tła według potrzeb */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .column-navbar-header ul {
        display: flex;
        list-style-type: none;
        gap: 1.6rem;
        color: #FFF;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 35px;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }

    .column-navbar-header li:active, li:hover {
        text-wrap: nowrap;
        color: #F5C400;
    }

    .column-navbar-header li:hover {
        border-bottom: none;
        cursor: grabbing;
    }

}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1400px) {
    .column-socialmedia-header {
        display: none;
    }
    .section-header {
        width: 80%;
        margin: 0;
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
    }
    .background-header {
        display: flex;
        background-color: #212020;
        height: 100%;
        justify-content: center;
    }

    .column-logo-header {
        width: 120px;
    }
    .column-navbar-header {
        width: 50px;
    }
    .expand-menu {
        display: none;
        position: absolute;
        border-radius: 15px;
        width: 250px;
        margin-left: -8rem;
        top: 100%;
        left: 0;
        background-color: #fff; /* Dostosuj kolor tła według potrzeb */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 1025px) and (max-width: 1100px) {
    .column-socialmedia-header {
        display: none;
    }
    .section-header {
        width: 90%;
        margin: 0;
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .background-header {
        display: flex;
        background-color: #212020;
        height: 100%;
        justify-content: center;
    }

    .column-logo-header {
        width: 120px;
    }
    .column-navbar-header {
        width: 50px;
    }
    .expand-menu {
        display: none;
        position: absolute;
        border-radius: 15px;
        width: 250px;
        margin-left: -8rem;
        top: 100%;
        left: 0;
        background-color: #fff; /* Dostosuj kolor tła według potrzeb */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
}


@media screen and (max-width: 800px) {
    .section-header {
        width: 88%;
        margin: 0;
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
    }
    .background-header {
        display: flex;
        background-color: #212020;
        height: 100%;
        justify-content: center;
    }

    .column-logo-header {
        width: 120px;
    }
    .column-navbar-header {
        width: 50px;
    }
}

