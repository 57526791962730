
.background-homepage {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-homepage {
    display: flex;
    width: 1280px;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 14rem;
    justify-content: space-between;
}

.section-homepage-text {
    width: 40%;
}

.section-homepage-text {
    display: flex;
    flex-direction: column;
}


.section-homepage-buttons {
    display: flex;
    margin-top: 1.5rem;
    gap: 1rem;
}

button.homepage-wesprzyjnas {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 214px;
    height: 52px;
    border-radius: 15px;
    background-color: #980808;
    border: none;
    gap: 0.2rem;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
  }

button.homepage-wesprzyjnas:hover, button.homepage-wesprzyjnas:active, button.homepage-inwestuj:hover, button.homepage-inwestuj:active {
    animation: shake 0.5s ease-in-out;
    cursor: pointer;
}

button.homepage-inwestuj {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 52px;
    border-radius: 15px;
    background: #000;
    border: none;
    gap: 0.2rem;
}

.section-homepage-image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 58%;
  }
  
  .homepage-img,
  .homepage-imgborder {
    width: 100%; 
    height: 100%;
    display: block;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .homepage-img {
    position: relative; 
    margin-left: -0.5rem;
    z-index: 1; 
    shape-rendering: crispEdges;
    filter:drop-shadow();
  }
  
  .homepage-imgborder {
    position: absolute; 
    top: 0;
    left: 0;
    z-index: 0; 
  }


  /* TABLET POZIOMO */
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-homepage {
      display: flex;
      width: 90%;
    }
    .section-homepage-text {
      width: 45%;
    }
    .section-homepage-image {
      width: 50%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  /* TABLET PIONOWO */
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .background-homepage {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .section-homepage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5rem;
      width: 80%;
    }
    .section-homepage-text {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .section-homepage-image {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .section-homepage-buttons {
      width: 80%;
    }
  }

  @media screen and (max-width: 800px) {
    .background-homepage {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .section-homepage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5rem;
      margin-bottom: 5rem;
      width: 100%;
    }
    .section-homepage-text {
      width: 80%;
    }
    .section-homepage-image {
      width: 88%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }