.background-actions {
    display: flex;
    background-color: #252525;
    justify-content: center;
}

.section-actions {
    display: flex;
    width: 1280px;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
  }

button.redirect-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 289px;
    height: 52px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: #000;
    border: none;
}

button.redirect-actions:hover, button.redirect-actions:active {
    background-color: #BA9500;
    animation: shake 0.5s ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .section-actions {
        display: flex;
        flex-direction: column;
        width: 88%;
        justify-content: center;
        align-items: center;
    }
}