.background-aboutus {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-aboutus {
    display: flex;
    width: 1280px;
    justify-content: space-between;
    align-items: center;
    margin-top: 12rem;
    margin-bottom: 12rem;
}

.section-aboutus-image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 55%;
}

.section-aboutus-text {
    width: 40%;
}
  
  .aboutus-img,
  .aboutus-imgborder {
    width: 100%; /* Adjust the width as needed */
    height: 100%;
    display: block;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .aboutus-img {
    position: relative; /* Set position to relative */
    margin-left: -0.5rem;
    z-index: 1; /* Make sure it's above other elements with lower z-index */
    shape-rendering: crispEdges;
    filter:drop-shadow();
  }
  
  .aboutus-imgborder {
    position: absolute; /* Set position to absolute */
    top: 0;
    left: 0;
    z-index: 0; /* Lower z-index to appear below other elements */
  }

    /* TABLET PIONOWO */
    @media screen and (min-width: 801px) and (max-width: 1024px) {
      .section-aboutus {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
      }
      .section-aboutus-text, .section-aboutus-image {
        width: 80%;
      }
    }

  /* TABLET POZIOMO */
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-aboutus {
      width: 90%;
    }
  }

  @media screen and (max-width: 800px) {
    .section-aboutus {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 88%;
    }
    .section-aboutus-text, .section-aboutus-image {
      width: 100%;
    }
  }