.background-statut {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-statut {
    display: flex;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 6rem;
    padding-top: 6.2rem;
    padding-bottom: 6.2rem;
}

.scrollable-container {
    width: 100%; /* szerokość kontenera */
    height: 500px; /* wysokość kontenera */
    overflow-y: auto; /* umożliwia przewijanie tylko pionowe */
    overflow-x: hidden; /* wyłącza przewijanie poziome */
    padding: 10px; /* opcjonalne, dla lepszego widoku */
    white-space: normal; /* pozwala na zawijanie tekstu */
}
  
.scrollable-container p {
    margin: 0;
    word-wrap: break-word; /* zawijanie tekstu, gdy jest zbyt długi dla kontenera */
}
  
.statut-header-container {
    display: flex;
    justify-content: space-between;
} 

.statut-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-top: 1.2rem;
}

.statut-text-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 0.6rem;
}

h1.statut {
    font-size: 28px;
}

h3.statut {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

p.statut {
    color: #FFF;
    font-family: "Roboto Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 800px) {
    .section-statut {
        width: 90%;
    }

    .statut-header-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
    }

    h1.statut {
        font-size: 24px;
    }

    h3.statut {
        font-size: 17px;
    }

    p.statut {
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-statut {
        width: 90%;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-statut {
        width: 80%;
    }
    
    .statut-header-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
    }
}