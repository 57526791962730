.background-invest {
    display: flex;
    background-color: #F5C400;
    justify-content: center;
}

.section-invest {
    display: flex;
    flex-direction: column;
    width: 1280px;
    justify-content: space-between;
    margin-top: 6rem;
    margin-bottom: 12rem;
}

.section-invest-containers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3rem;
}

.section-invest-container-left, .section-invest-container-center, .section-invest-container-right {
    width: 396px;
    height: 496px;
    background: #212020;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
}

.section-invest-container-left {
    border-radius: 150px 50px;
}

.section-invest-container-center {
    border-radius: 50px;
}

.section-invest-container-right {
    border-radius: 50px 150px;
}

.section-invest-bizneslama {
    margin-top: -5rem;
    margin-bottom: -7.5rem;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.invest-caption-img {
    width: 60px;
    height: 60px;
}

.section-invest-container-content {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.suwak-container {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
  
.suwak {
    width: 100%;
    margin: 0 auto;
    appearance: none;
    height: 15px;
    border-radius: 15px;
    background: #fff;
    outline: none;
  }
  
  .suwak::-webkit-slider-thumb {
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #F5C400;
    cursor: pointer;
  }
  
  .suwak::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #F5C400;
    cursor: pointer;
  }
  
  .suwak::-ms-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #F5C400;
    cursor: pointer;
  }



  .invest-form {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .invest-form input {
    width: 270px;
    height: 65px;
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: #252525;
    padding-left: 1rem;
    padding-right: 1rem;


    color: #9A9A9A;
    font-family: 'Roboto Medium';
    font-size: 17px;
  }

  .invest-form input:focus {
    border-color: none;
  }

  /* Styl dla placeholdera w elemencie input */
input::placeholder {
    color: #9A9A9A;
    font-family: 'Roboto Medium';
    font-size: 17px;
    text-align: center;
  }
  
  /* Dodatkowy styl, gdy input ma focus */
  input:focus::placeholder {
    color: #666; /* Kolor tekstu placeholdera po uzyskaniu focusu */
  }

  .section-invest-form {
    margin-top: 2rem;

    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  img.bizneslama {
    width: 250px;
    margin-bottom: 1rem;
  }

  /* Stylizacja checkboxa */
.checkbox-container-invest {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
    gap: 10px;
  }


  
  .checkbox-input-invest {
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid #000;
    background: #FFF;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  
  .checkbox-input-invest:checked {
    background-color:black;
    background-image: url(../images/invest/checked.svg);
    border: none;
  }

.section-invest-checkboxs {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.7rem;
}


/* SUMUP CONTAINER */

.section-invest-sumup-container {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;
}

button.invest-gotopayment {
    width: 261px;
    height: 57px;
    border-radius: 15px;
    background: #980808;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 0.2rem;
}

button.invest-gotopayment:hover {
    animation: shake 0.5s ease-in-out;
    cursor: pointer;
    background-color: black;
}

.sumup-amount {
    width: 360px;
}

.checkbox-fixed {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
  }

  
    /* TABLET PIONOWO */
    @media screen and (min-width: 801px) and (max-width: 1024px) {
      .section-invest-containers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }

      .section-invest {
        width: 80%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      .section-invest-bizneslama {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
      }

      .section-invest-container-left, .section-invest-container-center, .section-invest-container-right {
        width: 90%;
        height: 436px;
        background: #212020;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        text-align: start;
        justify-content: center;
      }

      .section-invest-form {
        display: flex;
        flex-direction: column;
        text-align: start;
      }

      .section-invest-sumup-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      }

      .invest-form {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
      }
  
      .invest-form input {
        width: 100%;
      }
  
      .invest-form label {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .section-invest-checkboxs {
        width: 90%;
      }

      img.invest-payments-methods {
        display: none;
      }

      .section-invest-sumup-container {
        display: flex;
        flex-direction: row;
        width: 90%;
      }

      button.invest-gotopayment {
        width: 40%;
      }
    }

  /* TABLET POZIOMO */
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-invest {
      width: 90%;
    }

    .section-invest-containers {
      gap: 1rem;
    }
    .section-invest-container-left, .section-invest-container-center, .section-invest-container-right {
      width: 376px;
      height: 496px;
      background: #212020;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
    }

    .invest-form input {
      width: 260px;
    }

    img.invest-payments-methods {
      width: 430px;
    }
  }

  @media screen and (max-width: 800px) {

    .section-invest-containers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .section-invest {
      width: 88%;
      text-align: center;
    }

    .section-invest-bizneslama {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
    }

    .section-invest-container-left, .section-invest-container-center, .section-invest-container-right {
      width: 100%;
      height: 496px;
      background: #212020;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      text-align: start;
      justify-content: center;
    }

    .section-invest-form {
      display: flex;
      flex-direction: column;
      text-align: start;
    }

    .section-invest-sumup-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    img.bizneslama {
      width: 200px;
      margin-bottom: 1rem;
    }

    .invest-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }

    .invest-form input {
      width: 90%;
    }

    .invest-form label {
      width: 100%;
    }

    .checkbox-container-invest {
      display: flex;
      align-items: center;
      margin: 0px;
      padding: 0px;
      gap: 10px;
    }

    .section-invest-checkboxs {
      width: 100%;
    }

    input::placeholder {
      font-size: 15.8px;
    }

    img.invest-payments-methods {
      display: none;
    }

    button.invest-gotopayment {
      width: 100%;
    }
  }
  