.background-payment {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-payment {
    display: flex;
    flex-direction: row;
    width: 1280px;
    padding-top: 20rem;
    padding-bottom: 10rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    flex: 1;
}

.section-payment-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* To nadal może pomóc */
    max-width: 100%; /* Zapobiega rozciąganiu poza szerokość kontenera */
    box-sizing: border-box;
}


.section-payment-happyimage, .section-payment-wtfimage {
    position: absolute; 
    top: 12rem; 
    left: 32.5rem; /* Dostosuj według potrzeby */
    width: auto; 
    height: auto;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.section-payment-wtfimage {
    left: 65rem; 
    top: 12rem;
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-payment {
        height: 35vh;
        width: 90%;
    }

    .section-payment-wtfimage {
        left: 30rem; 
        top: 22rem;
    }

    .section-payment-happyimage {
        position: absolute; 
        top: 22rem; 
        left: 6.5rem; /* Dostosuj według potrzeby */
        width: auto; 
        height: auto;
        display: flex; 
        align-items: center;
        justify-content: center;
    }
}

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-payment-wtfimage {
        left: 42rem; 
        top: 12rem;
    }

    .section-payment-happyimage {
        position: absolute; 
        top: 12em; 
        left: 11rem; /* Dostosuj według potrzeby */
        width: auto; 
        height: auto;
        display: flex; 
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .section-payment {
        height: 11vh;
        width: 90%;
    }

    .section-payment-happyimage {
        position: absolute; 
        top: 12em; 
        left: 16rem; /* Dostosuj według potrzeby */
        width: auto; 
        height: auto;
        display: flex; 
        align-items: center;
        justify-content: center;
    }

    .section-payment-wtfimage {
        left: 48rem; 
        top: 12rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .section-payment-wtfimage {
        left: 38rem; 
        top: 22rem;
    }
}

@media screen and (max-width: 800px) {
    .section-payment {
        width: 80%;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .section-payment-wtfimage, .section-payment-happyimage {
        margin-top: 1rem;
        display: flex !important;
        width: 100% !important;
        left: 0rem; 
        top: 0rem;
    }
}