.background-footer {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-footer {
    display: flex;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 6rem;
}

.socialicon {
color: black; /* Kolor domyślny */
transition: color 0.3s ease; /* Animacja zmiany koloru */
}

.socialicon:hover {
    animation: shake 0.5s ease-in-out;
    cursor: pointer;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
}

/* FOOTER */

img.logo-footer {
    width: 140px;
    height: 140px;
}

img.logo-footer:hover {
    animation: shake 0.5s ease-in-out;
}

.logo-name-socialmedia-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.socialmedia-footer-container {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    gap: 0.5rem;
}

.important-links-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

p.importantlinks-footer:hover {
    color: rgb(184, 165, 91) 
}
/* COPYRIGHT FOOTER */

.background-copyrightfooter {
    display: flex;
    background-color: #222222;
    justify-content: center;
}

.section-copyrightfooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1280px;
    height: 96px;
}

@media screen and (max-width: 800px) {
    .section-copyrightfooter {
        width: 90%;
        height: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    p.copyright-footer {
        margin: 0;
    }


    .background-footer {
        display: flex;
        background-color: #212020;
        justify-content: center;
    }
    
    .section-footer {
        display: flex;
        flex-direction: column;
        width: 85%;
        justify-content: space-between;
        margin-bottom: 6rem;
    }
    
    
    
    
    
    /* FOOTER */
    
    img.logo-footer {
        width: 100px;
        height: 100px;
    }
    
    .logo-name-socialmedia-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    
    .socialmedia-footer-container {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        align-items: flex-end;
        text-align: end;
        gap: 0.5rem;
    }

    .socialmedia-footer-container-mobile {
        display: flex;
        gap: 0.3rem;
    }
    
    .important-links-footer {
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }

    p.fundationname-footer {
        font-size: 1.2rem;
        text-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    p.head-footer {
        font-size: 18px;
    }

    div.footer-mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    img.socialicon {
        width: 32px;
    }

    div.socialmedia-importantlinks-mobile {
        margin-top: 0.7rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

  /* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-footer {
        width: 90%;
    }

    .section-copyrightfooter {
        width: 90%;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-copyrightfooter {
        width: 90%;
        height: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 2rem;
    }

    p.copyright-footer {
        margin: 0;
    }


    .background-footer {
        display: flex;
        background-color: #212020;
        justify-content: center;
    }
    
    .section-footer {
        display: flex;
        flex-direction: column;
        width: 85%;
        justify-content: space-between;
        margin-bottom: 6rem;
    }
    
    
    
    
    
    /* FOOTER */
    
    img.logo-footer {
        width: 100px;
        height: 100px;
    }
    
    .logo-name-socialmedia-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    
    .socialmedia-footer-container {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        align-items: flex-end;
        text-align: end;
        gap: 0.5rem;
    }

    .socialmedia-footer-container-mobile {
        display: flex;
        gap: 0.3rem;
    }
    
    .important-links-footer {
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }

    p.fundationname-footer {
        font-size: 1.2rem;
        text-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    p.head-footer {
        font-size: 18px;
    }

    div.footer-mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    img.socialicon {
        width: 32px;
    }

    div.socialmedia-importantlinks-mobile {
        margin-top: 0.7rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }  
}