.background-facebookfeed {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-facebookfeed {
    display: flex;
    flex-direction: column;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 6rem;
}

.section-facebookfeed-header {
    width: 100%;
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.section-facebookfeed-wpisy {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.facebookfeed-container {
    width: 396px;
    height: 100%;  
    padding-bottom: 0.7rem;
    border-radius: 25px;
    background: #252525;  
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
}

.publishday-and-author {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.logo-and-author {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

img.author-logo {
    width: 45px;
    height: 45px;
}

img.post-image {
    width: 100%;
    height: 237.95px;
    border-radius: 25px 25px 0px 0px;
    image-rendering: optimizeSpeed;
}

.reactions-readmore-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.5rem;
}

.reactions {
    display: flex;
    margin-left: 2rem;
}

.reaction-container {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}


button.readmore {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 210px;
    height: 52px;
    border-radius: 15px;
    background: #000;
    margin-right: 2rem;

    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

button.readmore:hover, button.readmore:active {
    background-color: #BA9500;
    cursor: pointer;
    animation: shake 0.5s ease-in-out;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
}

@media screen and (max-width: 800px) {
    .section-facebookfeed {
        width: 88%;
        display: flex;
    }
    .section-facebookfeed-wpisy {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .facebookfeed-container {
        width: 100%;
        height: 560px;
    }

    .reactions-readmore-container {
        margin-top: 1.5rem;
        gap: 1rem;
    }

    .publishday-and-author {
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }

    .logo-and-author {
        margin-top: -1.5rem;
    }

    button.readmore {
        width: 50%;
    }

    .section-facebookfeed-header {
        text-align: center;
    }

    img.post-image {
        height: 240px;
    }

    img.reactions-fb-icon {
        width: 55px;
    }
}

  /* TABLET PIONOWO */
  @media screen and (min-width: 701px) and (max-width: 1024px) {
    .section-facebookfeed {
        width: 80%;
        display: flex;
    }
    .section-facebookfeed-wpisy {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
        align-items: center;
    }
    .facebookfeed-container {
        width: 600px;
        height: 630px;
    }

    .reactions-readmore-container {
        margin-top: -2rem;
    }

    button.readmore {
        width: 410px;
    }

    .section-facebookfeed-header {
        text-align: center;
    }

    .publishday-and-author {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    img.post-image {
        height: 380px;
    }

    .publishday-and-author {
        gap: 3rem;
    }

    .logo-and-author {
        margin-top: 0rem;
    }
  }

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-facebookfeed {
        width: 90%;
        display: flex;
    }
    .section-facebookfeed-wpisy {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .facebookfeed-container {
        height: 540px;
        width: 32%;
    }
    img.post-image {
        height: 250px;
    }

    .reactions-readmore-container {
        margin-top: 2.5rem;
        gap: 1rem;
        text-wrap: nowrap;
    }

    button.readmore {
        width: 170px;
    }
}