.mobiledropdown {
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    top: 16%;
    gap: 0.5rem;
    background-color: white;
    padding: 1.5rem;
    margin: 5%;
    border-radius: 2rem;
    z-index: 2;
    
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1300px) {
    .mobiledropdown {
        top: 6%;
        margin: 10%;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 1025px) and (max-width: 1100px) {
    .mobiledropdown {
        top: 12%;
        margin: 5%;
    }
}

.mobiledropdown button {
    width: 100%;
    height: 52px;
    background-color: white;
    color: black;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    border: none;
    display: flex;
    margin: 0;
    padding: 0;
}

button.importantlink-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobiledropdown-importantlinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}
  
p.importantlink-p {
    font-size: 14px;
    color: black;
    font-family: Poppins;
    font-weight: 500;
    border-bottom: 5px solid #F5C400;
}

img.m-socialmedia {
    filter: invert(1);
}

.mobiledropdown-socialmedia {
    display: flex;
    justify-content: center;
    gap: 0.6rem;
}