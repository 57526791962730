@font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-ExtraLight/Poppins-ExtraLight.eot');
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('../Poppins/Poppins-ExtraLight/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-ExtraLight/Poppins-ExtraLight.woff2') format('woff2'),
        url('../Poppins/Poppins-ExtraLight/Poppins-ExtraLight.woff') format('woff'),
        url('../Poppins/Poppins-ExtraLight/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Medium/Poppins-Medium.eot');
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('../Poppins/Poppins-Medium/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Medium/Poppins-Medium.woff2') format('woff2'),
        url('../Poppins/Poppins-Medium/Poppins-Medium.woff') format('woff'),
        url('../Poppins/Poppins-Medium/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Lightitalic/Poppins-LightItalic.eot');
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('../Poppins/Poppins-Lightitalic/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Lightitalic/Poppins-LightItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-Lightitalic/Poppins-LightItalic.woff') format('woff'),
        url('../Poppins/Poppins-Lightitalic/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-ExtraLightItalic/Poppins-ExtraLightItalic.eot');
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('../Poppins/Poppins-ExtraLightItalic/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-ExtraLightItalic/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-ExtraLightItalic/Poppins-ExtraLightItalic.woff') format('woff'),
        url('../Poppins/Poppins-ExtraLightItalic/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Regular/Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('../Poppins/Poppins-Regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Regular/Poppins-Regular.woff2') format('woff2'),
        url('../Poppins/Poppins-Regular/Poppins-Regular.woff') format('woff'),
        url('../Poppins/Poppins-Regular/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-SemiBold/Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../Poppins/Poppins-SemiBold/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-SemiBold/Poppins-SemiBold.woff2') format('woff2'),
        url('../Poppins/Poppins-SemiBold/Poppins-SemiBold.woff') format('woff'),
        url('../Poppins/Poppins-SemiBold/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Black/Poppins-Black.eot');
    src: local('Poppins Black'), local('Poppins-Black'),
        url('../Poppins/Poppins-Black/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Black/Poppins-Black.woff2') format('woff2'),
        url('../Poppins/Poppins-Black/Poppins-Black.woff') format('woff'),
        url('../Poppins/Poppins-Black/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-ExtraBoldItalic/Poppins-ExtraBoldItalic.eot');
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('../Poppins/Poppins-ExtraBoldItalic/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-ExtraBoldItalic/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-ExtraBoldItalic/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../Poppins/Poppins-ExtraBoldItalic/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-MediumItalic/Poppins-MediumItalic.eot');
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('../Poppins/Poppins-MediumItalic/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-MediumItalic/Poppins-MediumItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-MediumItalic/Poppins-MediumItalic.woff') format('woff'),
        url('../Poppins/Poppins-MediumItalic/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Light/Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'),
        url('../Poppins/Poppins-Light/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Light/Poppins-Light.woff2') format('woff2'),
        url('../Poppins/Poppins-Light/Poppins-Light.woff') format('woff'),
        url('../Poppins/Poppins-Light/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Bold/Poppins-Bold.eot');
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('../Poppins/Poppins-Bold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Bold/Poppins-Bold.woff2') format('woff2'),
        url('../Poppins/Poppins-Bold/Poppins-Bold.woff') format('woff'),
        url('../Poppins/Poppins-Bold/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Thin/Poppins-Thin.eot');
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('../Poppins/Poppins-Thin/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Thin/Poppins-Thin.woff2') format('woff2'),
        url('../Poppins/Poppins-Thin/Poppins-Thin.woff') format('woff'),
        url('../Poppins/Poppins-Thin/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Italic/Poppins-Italic.eot');
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('../Poppins/Poppins-Italic/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Italic/Poppins-Italic.woff2') format('woff2'),
        url('../Poppins/Poppins-Italic/Poppins-Italic.woff') format('woff'),
        url('../Poppins/Poppins-Italic/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-ThinItalic/Poppins-ThinItalic.eot');
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('../Poppins/Poppins-ThinItalic/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-ThinItalic/Poppins-ThinItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-ThinItalic/Poppins-ThinItalic.woff') format('woff'),
        url('../Poppins/Poppins-ThinItalic/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Blackitalic/Poppins-BlackItalic.eot');
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('../Poppins/Poppins-Blackitalic/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Blackitalic/Poppins-BlackItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-Blackitalic/Poppins-BlackItalic.woff') format('woff'),
        url('../Poppins/Poppins-Blackitalic/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Bolditalic/Poppins-BoldItalic.eot');
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('../Poppins/Poppins-Bolditalic/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Bolditalic/Poppins-BoldItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-Bolditalic/Poppins-BoldItalic.woff') format('woff'),
        url('../Poppins/Poppins-Bolditalic/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-SemiBoldItalic/Poppins-SemiBoldItalic.eot');
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('../Poppins/Poppins-SemiBoldItalic/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-SemiBoldItalic/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../Poppins/Poppins-SemiBoldItalic/Poppins-SemiBoldItalic.woff') format('woff'),
        url('../Poppins/Poppins-SemiBoldItalic/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../Poppins/Poppins-Extrabold/Poppins-ExtraBold.eot');
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('../Poppins/Poppins-Extrabold/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../Poppins/Poppins-Extrabold/Poppins-ExtraBold.woff2') format('woff2'),
        url('../Poppins/Poppins-Extrabold/Poppins-ExtraBold.woff') format('woff'),
        url('../Poppins/Poppins-Extrabold/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }