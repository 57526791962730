.background-catchus {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-catchus {
    display: flex;
    flex-direction: row;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 12rem;
    margin-top: 8rem;
}

.section-headquarters, .section-contactus {
    width: 37%;
}

.nip-krs {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.data-headquarters {
    margin-top: 3rem;
}

iframe.googlemaps {
    margin-top: 2rem;
    width: 100%;
    height: 383px;
    border: none;
    border-radius: 50px;
}

/* FORMULARZ PONIZEJ STYLE */


form.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.3rem;
  margin-top: 3rem;
}

.contact-form label.required {
  width: 100%;
  background: #252525;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 25px;
}

.contact-form label.optional {
  width: 100%;
  background: #252525;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 25px;
}

.contact-form input.required{
  width: 70%;
  height: 70px;
  margin: 0;
  background: #252525;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 25px;
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.required-text {
  width: 30%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
}

.required-text-textarea {
  width: 30%;
  display: flex;
  text-align: center;
  padding-top: 1.6rem;
}

.contact-form  textarea {
  width: 100%;
  height: 206px;
  margin: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.6rem;
  background: #252525;
  border: none;
  border-radius: 25px;
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.contact-form textarea::-webkit-scrollbar {
  display: none;
}

.contact-form input {
  width: 100%;
  height: 70px;
  margin: 0;
  background: #252525;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 25px;
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.contact-form input:focus, textarea:focus {
  outline: none;
}

.contact-form input::placeholder, textarea::placeholder {
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.contact-form input:focus::placeholder, textarea:focus::placeholder {
  color: #666; /* Kolor tekstu placeholdera po uzyskaniu focusu */
}

button.contact-form-send {
  width: 100%;
  height: 52px;
  border-radius: 15px;
  background: #000;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.contact-form-send:hover {
  cursor: pointer;
  background: #BA9500;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px) rotate(2.5deg); }
  50% { transform: translateX(2px) rotate(-2.5deg); }
  75% { transform: translateX(-2px) rotate(2.5deg); }
  100% { transform: translateX(0); }
}

/* CHECKBOX */


.contact-form-checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}

input.checkbox-contactform {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #000;
  background: #FFF;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

input.checkbox-contactform:checked {
  background-color:black;
  background-image: url(../images/invest/checked.svg);
  border: none;
}

@media screen and (max-width: 800px) {
  .section-catchus {
    width: 88%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  .required-text {
    width: 40% !important;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
  }
  .section-contactus, .section-headquarters {
    width: 100%;
  }
  .contact-form input::placeholder, textarea::placeholder {
    font-size: 14.8px;
  }

  .header-contactus, .header-headquarters {
    text-align: center;
  }

  iframe.googlemaps {
    width: 100%;
    height: 350px;
  }
}

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .section-catchus {
    width: 90%;
    display: flex;
    gap: 5rem;
  }

  .section-headquarters, .section-contactus {
    width: 40%;
  }
}

  
/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
  .section-catchus {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  .section-contactus, .section-headquarters {
    width: 100%;
  }
  .contact-form input::placeholder, textarea::placeholder {
    font-size: 14.8px;
  }

  .header-contactus, .header-headquarters {
    text-align: center;
  }

  iframe.googlemaps {
    width: 100%;
    height: 500px;
  }
}