.background-stats {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-stats {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 1280px;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.stats-container, .stats-container-homepage {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px 20px 20px;
    padding: 0.7rem 0.8rem 0.7rem 0.8rem;
    height: 55px;
    align-items: center;
    justify-content: center;
}

p.stats-head {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

p.stats-count {
    color: #8F7300;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;   
    margin: 0;
}

img.stats-img {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 800px) {
    .section-stats {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        width: 80% !important;
        margin-top: 3rem;
        margin-bottom: 3rem;
    } 

    .stats-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .stats-container-homepage {
        display: flex;
        width: 65%;
        align-items: center;
        justify-content: flex-start;
    }

    p.stats-head, p.stats-count {
        font-size: 15px;
    }
}

  /* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-stats {
        width: 90%;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-stats {
        width: 80%;
    }
}