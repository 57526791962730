.background-podcasts {
    display: flex;
    background-color: #212020;
    justify-content: center;
}

.section-podcasts {
    display: flex;
    flex-direction: column;
    width: 1280px;
    justify-content: space-between;
    margin-bottom: 6rem;
}

.podcasts-container {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.podcast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 396px;
    height: 393px;  
    border-radius: 25px;
    background: #252525;  
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
}

img.player-bg {
    mix-blend-mode: overlay;
    width: 396px;
    height: 239px;
}

.player {
    position: relative;
    display: inline-block; 
}

.play-button {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 52.52px;
    height: 60.351px;
    transition: transform 1s ease-in-out, width 1s ease-in-out, height 1s ease-in-out; /* Dodaj transition dla płynnego przejścia */
}

.play-button:hover, .play-button:active {
    transform: translate(-50%, -50%) scale(1.2); /* Przykładowy efekt - zwiększenie rozmiaru */
    width: 62.52px;
    height: 70.351px;
}


  
.author-and-date-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.podcast-author {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 1rem;
}

.spotify-iframe {
    width: 396px;
    height: 320px;
}

.youtube-iframe {
    width: 396px;
    height: 230px;
    border-radius: 25px 25px 0px 0px;
}

@media screen and (max-width: 800px) {
    .section-podcasts {
        width: 88%;
    }
    .podcasts-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .podcast-container {
        width: 100%;
        height: 400px;
    }

    img.player-bg {
        width: 100%;
        height: auto;
    }
    .podcast-author {
        margin-top: -0.5rem;
    }

    
}

/* TABLET POZIOMO */
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-podcasts {
        width: 90%;
    }
    .podcast-container {
        width: 32%;
        height: 380px;
    }

    img.player-bg {
        width: 100%;
        height: auto;
    }

    .podcast-author {
        margin-top: -0.5rem;
    }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .section-podcasts {
        width: 80%;
    }
    .podcasts-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
    }

    .podcast-container {
        width: 600px;
        height: 540px;
    }

    img.player-bg {
        width: 600px;
        height: auto;
    }
    .podcast-author {
        margin-top: 0rem;
    }
}

/* TABLET PIONOWO IPAD MINI */
@media screen and (min-width: 701px) and (max-width: 801px) {
    .section-podcasts {
        width: 80%;
    }
    .podcasts-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
    }

    .podcast-container {
        width: 600px;
        height: 540px;
    }

    img.player-bg {
        width: 600px;
        height: auto;
    }
    .podcast-author {
        margin-top: 0rem;
    }
}