.background-mission {
    display: flex;
    background-color: #212020;
    justify-content: center;
} 

.section-mission {
    margin-top: 5rem;
    display: flex;
    width: 1280px;
    justify-content: space-between;
}

.section-mission-text, .section-mission-donate {
    width: 40%;
}

.section-mission-donate {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.section-mission-paymentinfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
}



/* wybierz kwote */

.section-mission-choose-amount {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    margin-top: 0.5rem;
}  

label.another-amount {
    margin-top: 0.6rem;
    width: 100%;
    height: 52px;
}

input.another-amount {
    width: 94%;
    height: 52px;
    background: black;
    border-radius: 7px;
    border: none;
    outline: none;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #FFF;
    font-family: 'Roboto Medium';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

input.another-amount:focus {
    border-color: none;
}

button.choose-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 52px;
    border-radius: 7px;
    background: #000;
    border: none;
}

button.choose-amount.selected {
    background-color: #BA9500;
}

button.choose-amount:hover, button.gotopayment:hover {
    cursor: pointer;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px) rotate(2.5deg); }
    50% { transform: translateX(2px) rotate(-2.5deg); }
    75% { transform: translateX(-2px) rotate(2.5deg); }
    100% { transform: translateX(0); }
}

button.gotopayment:hover {
    animation: shake 0.5s ease-in-out;
}

button.gotopayment {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    height: 60px;
    border-radius: 15px;
    background: #980808;
    border: none;
    display: none;
}

.container-donate-showhide {
    display: flex;
    flex-direction: column;
    width: 100%;
}


  /* TABLET POZIOMO */
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    .section-mission {
        width: 90%;
        display: flex;
        flex-direction: row;
    }
    button.choose-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-wrap: nowrap;
        height: 52px;
        border-radius: 7px;
        background: #000;
        border: none;
    }
  }
  /* TABLET PIONOWO */
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .background-mission {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .section-mission {
        width: 80%;
        display: flex;
        flex-direction: row;
        gap: 4rem;
    }
    .section-mission-donate {
        width: 60%;
    }

    .section-mission-choose-amount {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    button.choose-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-wrap: nowrap;
        height: 52px;
        border-radius: 7px;
        background: #000;
        border: none;
    }
  }

  @media screen and (max-width: 800px) {
    .background-mission {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .section-mission {
        width: 88%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }
    .section-mission-text, .section-mission-donate {
        width: 100%;
    }

    .section-mission-choose-amount {
        grid-template-columns: repeat(2, 1fr);
    }
    button.choose-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-wrap: nowrap;
        height: 52px;
        border-radius: 7px;
        background: #000;
        border: none;
    }

    input.another-amount {
        width: 92%;
    }
  }