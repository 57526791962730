/* index.css */
body, html {
  margin: auto;
  padding: auto;
  height: 100%;
  
}
body>div {overflow-x:hidden;} /* whitespace fix na mobile */

html {
  scroll-behavior: smooth;
}

.cookies-container {
  position: fixed;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  max-width: 900px; 
  width: 100%; 
  background-color: #FFF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  gap: 1rem;
  z-index: 1000; 
}

.cookies-info {
  display: flex;
  align-items: center; 
  gap: 1rem;
}

.cookies-info img {
  width: 40px; 
}

.cookies-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

span.link:hover {
  color: rgb(184, 165, 91);
  cursor: pointer;
}

span.linkwhite:hover {
  color: #FFF;
  cursor: pointer;
}


@media screen and (max-width: 800px) {
  .cookies-container {
    max-width: 80%;
    flex-direction: column;
    gap: 0.1rem;
  }

  .cookies-buttons {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

p.cookies {
  color: #FFF;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.margin-6rem {
  height: 6rem;
  width: 100%;
  background-color: #212020;
}


body {
  font-family: 'Roboto Medium', sans-serif, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  background-color: #F5C400;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background-color: #f11946;
  z-index: 9999;
}

a {
  text-decoration: none;
  color: inherit;
}


/* FONTY */

h1 {
  color: #F5C400;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

h1.podcast-h1 {
  color: #F5C400;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  width: 400px;
}

h1.dark {
  color: #000;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

h1.sumup {
  color: #000;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

h2.invest {
  color: #F5C400;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-top: 0.5rem;
  text-align: center;
  width: 80%;
}

h3 {
  color: #F5C400;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

h4 {
  color: #F5C400;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

p.homepage {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 90%;
}

p.page {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 19.8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

p.persondescription {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 19.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

p.button {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p.button-black {
  color: #000;
  font-family: Poppins;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

p.button-homepage {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p.invest-button {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p.donate-button {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.button-chooseamount {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.grey {
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h1.bizneslama {
  margin-top: -3rem;
  margin-right: -2rem;
  color: #000;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.investdescription {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.education {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.education-profile {
  color: #F5C400;
  font-family: Poppins;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-top: 3px;
}

p.checkbox {
  color: #000;
  font-family: 'Roboto Medium';
  font-size: 15.8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin: 0;
}

p.publishdate {
  color: #AAA;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.author {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

p.postmessage {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0;

  height: 135px;
}

p.reaction {
  color: #FFF;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

p.button-readmore {
  color: #FFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p.podcast-title {
  color: #F5C400;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.5rem;
  width: 80%;
  height: 30px;
}

p.podcast-date {
  color: #AAA;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.podcast-author {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

p.catchus-nazwa {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.catchus-data {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 0.8rem;
}

p.contactform-privacypolicy {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 15.8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.contactform-checkbox {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 15.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.contactform-required {
  color: #980808;
  font-family: 'Roboto Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding-right: 2rem;
}

p.contactform-optional {
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

p.fundationname-footer {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.head-footer {
  color: #F5C400;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

p.importantlinks-footer {
  color: #9A9A9A;
  font-family: 'Roboto Medium';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.copyright-footer {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

p.team-personname-function {
  color: #BA9500;
  font-family: 'Roboto Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

p.team-personname {
  color: black;
  font-family: 'Roboto Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.2rem;
}

p.person-achievements-carrier {
  padding: 0rem 0.8rem 0rem 0.8rem;
  color: black;
  font-family: 'Roboto Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.person-description {
  color: #FFF;
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  h1.homepage {
    font-size: 26px;
  }

  h1 {
    font-size: 26px;
  }

  h1.dark {
    font-size: 26px;
  }

  h1.bizneslama {
    font-size: 24px;
  }
  p.homepage {
    font-size: 20px;
  }
  p.button-homepage {
    font-size: 13.5px;
    text-wrap: nowrap;
  }

  p.page, p.persondescription { 
    font-size: 17px;
  }

  p.grey {
    font-size: 15.5px;
    text-wrap: nowrap;
  }

  img.available-payments-method {
    width: 200px;
  }

  h2.invest {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p.checkbox {
    color: #000;
    font-family: 'Roboto Medium';
    font-size: 15.4px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
    margin: 0;
    padding: 0;
    white-space: wrap;
  }

  p.investdescription {
    font-size: 17px;
  }

  p.donate-button {
    font-size: 15px;
  }

  h1.sumup {
    font-size: 26px;
  }

  p.publishdate {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.author {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.button-readmore {
    font-size: 14px;
    text-wrap: nowrap;
  }

  p.podcast-title {
    font-size: 20px;
    height: 60px;
    overflow: hidden;
  }

  h1.podcast-h1 {
    width: 100%;
    font-size: 26px;
    text-align: center;
  }

  p.postmessage {
    overflow: hidden;
  }

  p.contactform-required {
    font-size: 14px;
  }

  p.catchus-nazwa {
    font-size: 20px;
  }
  p.catchus-data {
    font-size: 17px;
  }
}

/* TABLET PIONOWO */
@media screen and (min-width: 801px) and (max-width: 1024px) {
  h1.homepage {
    font-size: 26px;
    width: 79%;
    text-align: start;
  }

  p.button {
    font-size: 15px;
  }

  h1 {
    font-size: 26px;
  }

  h1.dark {
    font-size: 26px;
  }

  h1.bizneslama {
    font-size: 24px;
  }
  p.homepage {
    font-size: 20.5px;
    width: 80%;
    text-align: start;
  }
  p.button-homepage {
    font-size: 13.5px;
    text-wrap: nowrap;
  }

  p.page {
    font-size: 17px;
  }

  p.grey {
    font-size: 15.5px;
    text-wrap: nowrap;
  }

  img.available-payments-method {
    width: 180px;
  }

  h2.invest {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p.checkbox {
    color: #000;
    font-family: 'Roboto Medium';
    font-size: 15.4px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
    margin: 0;
    padding: 0;
    white-space: wrap;
  }

  p.investdescription {
    font-size: 17px;
  }

  p.donate-button {
    font-size: 15px;
  }

  h1.sumup {
    font-size: 26px;
  }

  p.publishdate {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.author {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.button-readmore {
    font-size: 14px;
    text-wrap: nowrap;
  }

  p.podcast-title {
    font-size: 20px;
    height: 60px;
    overflow: hidden;
  }

  h1.podcast-h1 {
    width: 100%;
    font-size: 26px;
    text-align: center;
  }

  p.postmessage {
    overflow: hidden;
    height: 123px;
  }

  p.contactform-required {
    font-size: 15px;
  }

  p.catchus-nazwa {
    font-size: 20px;
  }
  p.catchus-data {
    font-size: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  p.publishdate {
    text-wrap: nowrap;
  }
  p.author {
    text-wrap: nowrap;
  }

  p.podcast-title {
    font-size: 20px;
    height: 60px;
    overflow: hidden;
  }

  h1.homepage {
    font-size: 26px;
    width: 100%;
    text-align: start;
  }

  p.button {
    font-size: 15px;
  }

  h1 {
    font-size: 26px;
  }

  h1.dark {
    font-size: 26px;
  }

  h1.bizneslama {
    font-size: 24px;
  }
  p.homepage {
    font-size: 20px;
    width: 100%;
  }
  p.button-homepage {
    font-size: 14px;
    text-wrap: nowrap;
  }

  p.page {
    font-size: 17px;
  }

  p.grey {
    font-size: 15.5px;
    text-wrap: nowrap;
  }

  img.available-payments-method {
    width: 180px;
  }

  h2.invest {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p.checkbox {
    color: #000;
    font-family: 'Roboto Medium';
    font-size: 15.4px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
    margin: 0;
    padding: 0;
    white-space: wrap;
  }

  p.investdescription {
    font-size: 17px;
  }

  p.donate-button {
    font-size: 15px;
  }

  h1.sumup {
    font-size: 26px;
  }

  p.publishdate {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.author {
    font-size: 16px;
    text-wrap: nowrap;
  }

  p.button-readmore {
    font-size: 14px;
    text-wrap: nowrap;
  }

  p.podcast-title {
    font-size: 20px;
    height: 60px;
    overflow: hidden;
  }

  h1.podcast-h1 {
    width: 100%;
    font-size: 26px;
  }

  p.postmessage {
    overflow: hidden;
    height: 120px;
  }

  p.contactform-required {
    font-size: 15px;
  }

  p.catchus-nazwa {
    font-size: 20px;
  }
  p.catchus-data {
    font-size: 17px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 150px  #252525 inset !important; /* Ustawia tło na białe */
    -webkit-text-fill-color: #9A9A9A !important; /* Ustawia kolor tekstu na czarny */
    transition: background-color 5000s ease-in-out 0s; /* Dodatkowa właściwość, aby uniknąć migotania */
}